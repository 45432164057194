import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import theme, { pxToRem } from '../../theme/theme';
import Seo from '../../components/Seo';
import Container from '../../components/layout/Container';
import heroImg from '../../assets/hero/header-hero-image.jpg'; // TODO: minify, jpg, responsive
import heroImg2 from '../../assets/hero/hero-home-2.jpg';
import heroImg3 from '../../assets/hero/hero-home-3.jpg';
import Hero from '../../components/Hero';
import { PageProps } from '../../components/Page';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import media, { mediaBreakpoints } from '../../theme/media';
import Chevron from '../../components/Chevron';
import PostsList, { PostItem, PostCategory } from '../../components/Posts/PostsList';
import PropertyAutocomplete from '../../components/PropertyAutocomplete';
import { colsToWidth } from '../../utils/themeHelpers';
import ProjectDashboardTile, { ToProjectTile } from '../../components/Project/ProjectDashboardTile';
import RegionTitle from '../../components/RegionTitle';
import PaddedContainer from '../../components/layout/PaddedContainer';
import { SalesProjectListItemViewModel } from '../../utils/projectWebProxy';
import { useMountedState } from 'react-use';
import FrInformatikSection from './FrInformatikSection';
import { useMediaQuery } from '@material-ui/core';

const Root = styled.div`
      position: relative;
`;
const StyledCarousel = styled(Carousel)`
      position: relative;
      
      .carousel .slide{
            background-color: transparent;
            text-align: left;
      }
      
`;
const HeroCarousel = styled(StyledCarousel)`
      height: 0;
      opacity: 0;
`;
const CarouselGrid = styled(StyledCarousel)`
      display: grid;
      margin-right: auto;
      margin-left: auto;
      grid-row-gap: 40px;
`;
const AbsoluteContainer = styled.div`
      position: absolute;
      width: 100%;
      height: ${p => `${p.theme.hero.defaultHeightMob}px`};

      ${media.greaterThan('navMob')`
            height: ${p => `calc(100vh - ${p.theme.header.height}px)`};
      `}
      top: 0;
      left: 0;
      text-transform: uppercase;
`;
const AbsoluteContent = styled.div`
      position: absolute;
      display: inline-block;
      ${media.greaterThan('medium')`
            bottom: 11%;
            left: 18%;
      `}
      bottom: 10%;
      left: 5%;
`;
const CompanyTitle = styled.div`
      position: relative;
      ${media.greaterThan('medium')`
            font-size: 3vw;
            padding-right: 20px;
      `}
      font-size: 6vw;
      line-height: 1.5;
      font-weight: bold;
      color: #fff;
      display: inline-block;
      padding-right: 10px;
      &:after{
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            ${p => media.greaterThan('medium')`
                  border-left: 1.9vw solid transparent;
                  border-top: 1.9vw solid ${p.theme.color.primary};
                  width: 1.9vw;
                  height: 1.9vw;
            `}
            border-left: 4vw solid transparent;
            border-top: 4vw solid ${p => p.theme.color.primary};
            width: 4vw;
            height: 4vw;
      }
`;
const CompanySubTitle = styled.div`
      color: ${p => p.theme.color.primary};
      ${media.greaterThan('medium')`
            font-size: 1.5vw;
            margin-bottom: 1.5vw;
      `}
      line-height: 1;
      font-size: 4vw;
      font-weight: bold;
      margin-bottom: 1.5vw;
`;
const DotGroup = styled.div`
      display: grid;
      justify-self: center;
      grid-template-rows: 15px;
      grid-auto-columns: 15px;
      grid-column-gap: 5px;
      grid-auto-flow: column;
      margin-top:40px;
`;
const colorChange = keyframes`
      from{
            color: #fff;
      };
      to {
            color: ${theme.color.primary};
      };
`;
const AbsoluteScrollButton = styled.div<{animate: boolean}>`
      display: none;
      ${media.greaterThan('navMob')`
            display: grid;
      `}
      grid-row-gap: ${p => p.theme.grid.gutter}px;
      grid-auto-flow: row;
      position: absolute;
      bottom: 3%;
      animation: ${p => p.animate ? colorChange : ''}  1s ease-in-out 1;
      color: ${p => p.animate ? p.theme.color.primary : '#fff'};
      width: 100%;
      cursor: pointer;
      line-height: 0.8;
      text-align: center;
      overflow:hidden;
`;
const jump = keyframes`
  from {
    transform: translateY(0px) rotate(90deg);
  }
  to {
    transform: translateY(15px) rotate(90deg);
  }
`;

const ScrollChevron = styled(Chevron)<{animate: boolean}>`
      width: 100%;
      height: 34px;
      animation: ${p => p.animate ? jump : ''} 0.4s ease-in-out 2;
`;
const SquareCarouselDot = styled.div<{active: boolean, primary?: boolean}>`
      background-color: transparent;
      border: 1px solid;
      line-height: 0;
      border-color: ${p => p.primary ? p.theme.color.primary : '#fff'};
      &:active,&:hover,&:disabled{
            polygon{
                  display: block;
            }
      }
      polygon{
            display: ${p => p.active ? 'block' : 'none'};
            fill: ${p => p.primary ? p.theme.color.primary : ''};
      }
      padding: 0;
`;

const ProjectGroup = styled.div`
      height: 100%;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      grid-row-gap: ${p => p.theme.grid.gutter}px;
      ${media.greaterThan('navMob')`
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: column;
      `}
`;
const CenterAlign = styled.div`
      display: grid;
      max-width: ${p => colsToWidth(p.theme, 6)}px;
      padding: 0 40px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
`;
const CenterGrid = styled.div`
      display: grid;
      width: 100%;
      justify-items:center;
`;
const NavButtonBack = styled.div`
  background-color: transparent;
  align-self: center;
  border: none;
  width: 30px;
  height: 30px;
  padding: 0;
  display: none;
  position: absolute;
  top: calc(50% - 15px);
  cursor: pointer;
  z-index: 2;
  color: ${p => p.theme.color.primary};
  ${media.greaterThan('medium')`
      display: inline-block;
  `}
`;
const NavButtonNext = styled(NavButtonBack)`
  right: 0;
`;
const NavChevron = styled(Chevron)`
  width: 100%;
  height: 100%;
`;
const CrossFadeHero = styled(Hero)<{active: boolean}>`
      position: ${p => p.active ? 'relative' : 'absolute'};
      top: 0;
      opacity: ${p => p.active ? 1 : 0};
      transition: opacity 1.5s ease-in-out;
`;

const Title = styled(RegionTitle)`
    justify-self: center;
    text-align: center;
`;
const Tabs = styled.div`
      display: grid;
      grid-auto-columns: minmax(0px, 200px);
      grid-auto-flow: column;
      justify-self: center;
      justify-content: center;
      margin-top: ${p => pxToRem(30)}rem;
      margin-bottom: ${p => pxToRem(50)}rem;
`;
const Tab = styled.div<{active: boolean}>`
      cursor: pointer;
      position: relative;
      background-color: ${p => p.theme.color.background};
      color: ${p => p.theme.color.primary};
      padding: 10px 30px;
      border-bottom: ${p => p.active ? `3px solid ${p.theme.color.primary}` : 'none'};
      font-weight: ${p => p.active ? 'bold' : 'normal'};
      max-width: 200px;
      text-align: center;
      &:after {
            display: ${p => p.active ? 'none' : 'block'};
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            content: '';
            border-bottom: 3px solid ${p => p.theme.color.primary}; 
            transform: scaleX(0);  
            transition: transform 250ms ease-in-out;
            
      }
      &:hover{
            font-weight: bold;
      }
      &:hover:after{
            transform: scaleX(1);
      }
`;

const CarouselDot = (slideIndex: number, index: number, onClick: () => void, primary?: boolean) => {
      return (
            <SquareCarouselDot key={index} active={slideIndex === index} primary={primary} onClick={onClick}><svg viewBox="0 0 100 100"><polygon points="10,10 10,90 90,90 90,10" fill="#fff"/></svg></SquareCarouselDot>
      );
};

const HomeIndex = ({
      model
}: PageProps<Model>) => {

      const contentRef = React.createRef<HTMLDivElement>();
      const isMounted = useMountedState();
      const isMobile = useMediaQuery(`(max-width: ${mediaBreakpoints.navMob})`);

      const scrollToTopOfContent = () => {
            const contentTop = contentRef.current;
            if (contentTop) {
              window.scrollTo(0, contentTop.offsetTop);
            }
      };
      const projectPages = model.projects.reduce((pages, project, i) => {
            const pageIndex = Math.floor(i / (isMobile ? 1 : 2));
            pages[pageIndex] = isMobile ? [ToProjectTile(project)] : [...(pages[pageIndex] || []), ToProjectTile(project)];
            return pages;
          }, [] as any[]);
      const [currentHeroSlide, setCurrentHeroSlide] = useState(0);
      const [currentProjectSlide, setCurrentProjectSlide] = useState(0);
      const [animate, setAnimate] = useState({jump: false, color: false});
      const [postItems, setPostItems] = useState({items: model.news, category: PostCategory.news});

      let selectedCategory = PostCategory.news;

      const setPosts = (category: string) => {
            selectedCategory = PostCategory[category];
            setPostItems({items: selectedCategory === PostCategory.news ? model.news : model.blog, category: selectedCategory});
      };

      useEffect(() => {
            setTimeout(() => {
                  if (isMounted()) {
                        if (window.scrollY <= 1) {
                              setAnimate({jump: true, color: true});
                        } else {
                              setAnimate({jump: false, color: true});
                        }
                  }
            }, 1500);
      }, []);

      return (
            
            <Root>
                  <Seo/>
                  <HeroCarousel
                              autoPlay={true}
                              interval={3000}
                              transitionTime={0}
                              showThumbs={false}
                              infiniteLoop={true}
                              showIndicators={false}
                              showStatus={false}
                              showArrows={false}
                              selectedItem={currentHeroSlide}
                              onChange={setCurrentHeroSlide}
                  >
                        <div>Hero 1</div>
                        <div>Hero 1</div>
                        <div>Hero 3</div>
                  </HeroCarousel>
                  <CrossFadeHero src={heroImg} x={0} y ={0} active={currentHeroSlide === 0} height="full"/>
                  <CrossFadeHero src={heroImg2} x={0} y ={0} active={currentHeroSlide === 1} height="full"/>
                  <CrossFadeHero src={heroImg3} x={0} y ={0} active={currentHeroSlide === 2} height="full"/>
                  <AbsoluteContainer>
                        <AbsoluteContent>
                              <CompanyTitle>Frieden</CompanyTitle>
                              <CompanySubTitle>Willkommen daheim</CompanySubTitle>
                              <DotGroup>
                                    {CarouselDot(currentHeroSlide, 0, () => setCurrentHeroSlide(0))}
                                    {CarouselDot(currentHeroSlide, 1, () => setCurrentHeroSlide(1))}
                                    {CarouselDot(currentHeroSlide, 2, () => setCurrentHeroSlide(2))}
                              </DotGroup>
                        </AbsoluteContent>
                        <AbsoluteScrollButton animate={animate.color} onClick={scrollToTopOfContent}><ScrollChevron animate={animate.jump} direction="down" type="outlined"/>Scroll</AbsoluteScrollButton>
                  </AbsoluteContainer>
                  <div ref={contentRef}/>
                  <Container>
                        {model.projects.length > 0 &&
                        <PaddedContainer top={55} bottom={50}>
                              <RegionTitle align="center">Aktuelle projekte</RegionTitle>
                              <CarouselGrid
                                    autoPlay={false}
                                    transitionTime={1500}
                                    showThumbs={false}
                                    infiniteLoop={true}
                                    showIndicators={false}
                                    showStatus={false}
                                    showArrows={projectPages.length > 1}
                                    selectedItem={currentProjectSlide}
                                    onChange={setCurrentProjectSlide}
                                    renderArrowPrev={(onClickHandler, hasPrev) => 
                                          hasPrev && <NavButtonBack onClick={onClickHandler}><NavChevron direction="left"/></NavButtonBack>
                                    }
                                    renderArrowNext={(onClickHandler, hasNext) => 
                                          hasNext && <NavButtonNext onClick={onClickHandler}><NavChevron direction="right"/></NavButtonNext>
                                    }
                              >
                              {projectPages.map(page => 
                                          <ProjectGroup key={page.map(project => project.id).join('-')}>
                                                      {page.map(project => 
                                                      <ProjectDashboardTile key={project.id} tileWidth={490} project={project}/>       
                                                      )}
                                          </ProjectGroup>
                                    )}
                              </CarouselGrid>
                              <CenterGrid>
                                    <DotGroup>
                                          {projectPages.map((page, i) => 
                                                CarouselDot(currentProjectSlide % projectPages.length, i, () => setCurrentProjectSlide(i), true)
                                          )}
                                    </DotGroup>
                              </CenterGrid>
                        </PaddedContainer>}
                  </Container>
                  {!!(model.news && model.news.length) && <Container background="alt">
                        <PaddedContainer top={91} bottom={91} topMobile={21} bottomMobile={61}>
                              <Title compact>Aktuelles</Title>
                              <Tabs>
                                    {Object.values(PostCategory).filter(val => isNaN(Number(val)))
                                    .map(category => 
                                    <Tab key={category} active={PostCategory[category] === postItems.category} onClick={() => setPosts((category as string))}>
                                          {(category as string).charAt(0).toUpperCase() + (category as string).slice(1)}
                                    </Tab>)}
                              </Tabs>
                              <PostsList items={postItems.items} showAllButton={true} category={postItems.category}/>
                        </PaddedContainer>
                  </Container>}
                  <Container>
                        <PaddedContainer top={87} bottom={87}>
                              <CenterAlign>
                                    <PropertyAutocomplete buttonText="Anzeigen" action="link" title="Meine Liegenschaft">Informationen zu Ihrer Liegenschaft</PropertyAutocomplete>
                              </CenterAlign>
                        </PaddedContainer>
                  </Container>
                  <FrInformatikSection/>
            </Root>
      );
};

interface Model {
      news: PostItem[];
      blog: PostItem[];
      projects: SalesProjectListItemViewModel[];
}

export default HomeIndex;